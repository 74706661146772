import { memo, useEffect, useState } from "react";
import styled from "styled-components";

import { AuthenticatedUser } from "types/authenticatedUser";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
import { userSlice } from "reducers/userSlice";
import { RootState } from "store";
import { onlineAccountRegistrationStatusSelector, onlineAccountRegistrationUrlSelector, sophisticatedApplicationLinkLoadingSelector } from "selectors/userSelectors";
import { FONT_SIZES } from "../../constants";
import { clientSelector } from "selectors/clientSelectors";

const WholesaleWarningCellWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: start;
    padding-left: 0px;
    flex-direction: column;

    > b {
    }
  }
`;

const Title = styled.div<{
  $fontSize: string;
}>`
  color: #8e8e8e;
  font-weight: 700;
  font-size: ${(props) => props.$fontSize};
  width: 100%;
  white-space: pre-wrap;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  width: 100%;

  > span {
    flex: 1;
    padding: 0 7px;
  }
`;

const ActionButton = styled(PrimaryButton)`
  padding: 0 15px;
  height: 30px;
  width: auto;
  font-weight: 500;
`;

interface WholesaleWarningCellProps {
  authenticatedUser?: AuthenticatedUser;
  titleFontSize: string;
  collapsedView: boolean;
  primaryColorHex?: string;
}

const WholesaleWarningCell = (props: WholesaleWarningCellProps) => {
  const {
    authenticatedUser,
    collapsedView,
    titleFontSize,
    primaryColorHex = "#5BC64E",
  } = props;

  const dispatch = useAppDispatch();

  const [
    thisSophisticatedApplicationLinkLoading,
    setThisSophisticatedApplicationLinkLoading,
  ] = useState(false);

  const sophisticatedApplicationLinkLoading = useAppSelector(
    (state: RootState) => sophisticatedApplicationLinkLoadingSelector(state)
  );

  const onlineAccountRegistrationUrl = useAppSelector(
    (state: RootState) => onlineAccountRegistrationUrlSelector(state)
  );

  const onlineAccountRegistrationStatus = useAppSelector(
    (state: RootState) => onlineAccountRegistrationStatusSelector(state)
  );

  const client = useAppSelector((state: RootState) => clientSelector(state));

  useEffect(() => {
    if (!sophisticatedApplicationLinkLoading) {
      setThisSophisticatedApplicationLinkLoading(false);
    }
  }, [sophisticatedApplicationLinkLoading]);

  const onSignInClick = () => {    
    dispatch(uiSlice.actions.showSignInModal(true));
  };

  const onVerifyNowClick = () => {
    dispatch(uiSlice.actions.showIdentityDocumentModal(true));
  };

  const onApplyNowClick = () => {
    setThisSophisticatedApplicationLinkLoading(true);
    dispatch(userSlice.actions.loadSophisticatedApplicationLink());
  };

  const redirectToUrl = () => {
    window.open(client?.settings?.theme?.redirectUrl, "_blank");  
  }

  const handleCompleteNow = () => {
    window.open(onlineAccountRegistrationUrl, "_blank");    
  };

  return (
    <WholesaleWarningCellWrapper>
      <div>
        <Title $fontSize={titleFontSize}>
          {collapsedView ? "708 investors only" : "For 708 investors only"}
        </Title>
        {!authenticatedUser && (
          <ButtonsWrapper>
            <ActionButton
              onClick={() => client?.settings?.theme?.redirectMode ? redirectToUrl() : onSignInClick()}
              fontSize={FONT_SIZES.Medium}
              background="transparent"
              borderColor={primaryColorHex}
              color={primaryColorHex}
            >
              {collapsedView ? "Sign in" : "Sign in to view"} 
            </ActionButton>
          </ButtonsWrapper>
        )}
        {authenticatedUser &&
          authenticatedUser.verificationStatus !== "Verified" && 
          !client?.settings?.workflow?.enableOnlineAccountRegistrationForm && (
            <ButtonsWrapper>
              <ActionButton
                onClick={client?.settings?.workflow?.enableOnlineAccountRegistrationForm ? handleCompleteNow : onVerifyNowClick}
                fontSize={FONT_SIZES.Medium}
                background="transparent"
                borderColor={ onlineAccountRegistrationStatus === 1 ? "#838383" : "#5BC64E" }
                color={ onlineAccountRegistrationStatus === 1 ? "#838383" : "#5BC64E" }
                disabled={ onlineAccountRegistrationStatus === 1 }
              >
                Verify Now
              </ActionButton>
            </ButtonsWrapper>
          )}
        {authenticatedUser &&
          authenticatedUser.verificationStatus === "Verified" &&
          authenticatedUser.sophisticatedApplicationStatus !== "Approved" && (
            <ButtonsWrapper>
              <ActionButton
                onClick={onApplyNowClick}
                spinnerSize="sm"
                loading={thisSophisticatedApplicationLinkLoading}
                disabled={
                  thisSophisticatedApplicationLinkLoading ||
                  authenticatedUser.sophisticatedApplicationStatus === "Pending"
                }
                fontSize={FONT_SIZES.Medium}
                background="transparent"
                borderColor="#5BC64E"
                color="#5BC64E"
              >
                {authenticatedUser.sophisticatedApplicationStatus === "Pending"
                  ? "Application Pending"
                  : "Apply Now"}
              </ActionButton>
            </ButtonsWrapper>
          )}
      </div>
    </WholesaleWarningCellWrapper>
  );
};

export default memo(WholesaleWarningCell);
