import * as Yup from "yup";
import { memo, useState } from "react";
import { useEffect } from "react";
import Modal from "../modal/Modal";
import { useAppDispatch, useAppSelector } from "hooks";
import { userSlice } from "reducers/userSlice";
import { useFormik } from "formik";
import { RootState } from "store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSms, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  authenticatedUserSelector,
  onlineAccountRegistrationUrlSelector,
  resendingMobileVerificationSelector,
  resendMobileVerificationErrorSelector,
  resendMobileVerificationSuccessSelector,
  verifyingMobileSelector,
  verifyMobileErrorSelector,
  verifyMobileSuccessSelector,
} from "selectors/userSelectors";
import { Container, Form, Alert } from "react-bootstrap";
import Input from "components/input/Input";
import FormError from "components/formError/FormError";
import ModalNotice from "components/modalNotice/ModalNotice";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import LinkButton from "components/linkButton/LinkButton";
import { clientSelector } from "selectors/clientSelectors";
import styled from "styled-components";
import { VerifyMobile } from "types/verifyMobile";
import { uiSlice } from "reducers/uiSlice";
import { emailUs } from "utilities/contact";

const SmsIcon = styled(FontAwesomeIcon)`
  font-size: 6rem;
  color: #777777;
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 100% !important;
  margin-bottom: 10px;
`;

const EmailIcon = styled(FontAwesomeIcon)`
  font-size: 6rem;
  color: #777777;
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 100% !important;
  margin-bottom: 10px;
`;

const BottomNotice = styled(ModalNotice)`
  border-top: solid 1px silver;
  display: block;
`;

const EmailUsLink = styled(LinkButton)`
  width: auto;
  display: inline;
  min-height: auto;
  padding: 0 3px 0;
  margin-top: -3px;
`;

const MobileVerificationModal = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const [
    isManualResendOfMobileVerification,
    setIsManualResendOfMobileVerification,
  ] = useState(false);

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );

  const resendMobileVerificationSuccess = useAppSelector((state: RootState) =>
    resendMobileVerificationSuccessSelector(state)
  );

  const resendMobileVerificationError = useAppSelector((state: RootState) =>
    resendMobileVerificationErrorSelector(state)
  );

  const showMobileVerificationModal =
    authenticatedUser?.hasVerifiedMobile === false;

  useEffect(() => {
    if (showMobileVerificationModal) {
      dispatch(userSlice.actions.resendMobileVerification(true));
    }
  }, [dispatch, showMobileVerificationModal]);

  const verifyMobileError = useAppSelector((state: RootState) =>
    verifyMobileErrorSelector(state)
  );

  const verifyingMobile = useAppSelector((state: RootState) =>
    verifyingMobileSelector(state)
  );

  const resendingMobileVerification = useAppSelector((state: RootState) =>
    resendingMobileVerificationSelector(state)
  );

  const verifyMobileSuccess = useAppSelector((state: RootState) =>
    verifyMobileSuccessSelector(state)
  );

  const onCancel = () => {
    formik.resetForm();
    dispatch(userSlice.actions.signOut());
    setIsManualResendOfMobileVerification(false);
  };

  const schema = Yup.object({
    code: Yup.string().required("4 Digit Code is a required field."),
  });

  const onlineAccountRegistrationUrl = useAppSelector((state: RootState) =>
    onlineAccountRegistrationUrlSelector(state)
  );

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      code: "",
      password: "",
    },
    onSubmit: (values: any) => {
      const verifyMobile: VerifyMobile = {
        ...values,
      };

      dispatch(userSlice.actions.verifyMobile(verifyMobile));
    },
  });

  useEffect(() => {
    if (verifyMobileSuccess && showMobileVerificationModal) {
      dispatch(userSlice.actions.getAuthenticatedUser());
      dispatch(uiSlice.actions.showVerifyMobileSuccessModal(true));
      formik.resetForm();
      setIsManualResendOfMobileVerification(false);

      if (client?.settings?.workflow?.enableOnlineAccountRegistrationForm) {
        window.open(onlineAccountRegistrationUrl, "_blank");
      }      
    }
  }, [dispatch, formik, showMobileVerificationModal, verifyMobileSuccess]);

  const handleResendMobileVerifiation = () => {
    setIsManualResendOfMobileVerification(true);
    dispatch(userSlice.actions.resendMobileVerification(false));
  };

  const handleEmailUs = () => {
    emailUs(client?.contactEmail);
  };

  return (
    <Modal
      title={client?.settings?.workflow?.enableEmail2FA ? "Email Verification" : "Mobile Verification"}
      onCancel={onCancel}
      visible={showMobileVerificationModal}
    >
      <Form
        onSubmit={formik.handleSubmit}
        className="px-4 pb-2"
        autoComplete="off"
      >
        <Container>
          {client?.settings?.workflow?.enableEmail2FA ? <EmailIcon icon={faEnvelope} /> : <SmsIcon icon={faSms} />}
          <ModalNotice className="mb-4 text-center">
          {client?.settings?.workflow?.enableEmail2FA 
            ? `Please enter the 4 digit verification code that we've sent to your email.` 
            : `Please enter the 4 digit verification code that we've sent to your mobile ending with ${ authenticatedUser?.mobileLastDigits ?? "unknown" }.` }
          </ModalNotice>
          <Form.Group className="mb-4" controlId="code">
            <Input
              className="text-center"
              type="password"
              placeholder="4 Digit Code"
              value={formik.values.code}
              onChange={formik.handleChange}
              isTouched={formik.touched.code}
              errorMessage={formik.errors.code}
              maxLength={4}
            />
          </Form.Group>
          <FormError message={verifyMobileError} />
          <PrimaryButton
            color="white"
            background={client?.settings?.theme?.primaryColorHex}
            type="submit"
            disabled={verifyingMobile}
            loading={verifyingMobile}
          >
            Verify
          </PrimaryButton>
          {resendMobileVerificationError && (
            <FormError
              className="mt-4 mb-0"
              message={resendMobileVerificationError}
            />
          )}
          {resendMobileVerificationSuccess &&
            isManualResendOfMobileVerification && (
              <Alert className="mt-4 mb-0" variant="success">
                { client?.settings?.workflow?.enableEmail2FA
                  ? "A new 4 digit code has been sent to your email"
                  : "A new 4 digit code has been sent to your mobile"
                }
              </Alert>
            )}
          <LinkButton
            className="mt-2"
            disabled={
              resendingMobileVerification && isManualResendOfMobileVerification
            }
            loading={
              resendingMobileVerification && isManualResendOfMobileVerification
            }
            onClick={handleResendMobileVerifiation}
          >
            Resend
          </LinkButton>
          <BottomNotice className="text-center flex-row justify-content-center mt-2 pt-3">
            If you are experiencing any issues, please contact 
            <EmailUsLink
              fontSize="0.9rem"
              fontWeight="400"
              onClick={handleEmailUs}
            >
              {client?.contactEmail}
            </EmailUsLink>
            for more information
          </BottomNotice>
        </Container>
      </Form>
    </Modal>
  );
};

export default memo(MobileVerificationModal);
